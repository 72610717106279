<template>
  <div>
    <a-table
      :columns="columns"
      :dataSource="data"
      :scroll="{ x: 1800 }"
      :pagination="pagination"
      :loading="loading"
    >
      <template slot="chaozuo" slot-scope="text, record">
        <div>
          <a-popover placement="left">
            <template slot="content">
              <a-button type="primary">{{$t('operaterecords.a8')}}</a-button>
              <a-button type="primary">{{$t('operaterecords.a9')}}</a-button>
              <a-button type="primary">{{$t('operaterecords.a10')}}</a-button>
              <a-button type="primary">{{$t('operaterecords.a11')}}</a-button>
              <a-button type="primary">{{$t('operaterecords.a12')}}</a-button>
              <a-button type="primary" @click="() => remarks(record)"
                >{{$t('operaterecords.a13')}}</a-button
              >
            </template>
            <a>{{$t('operaterecords.a14')}}</a>
          </a-popover>
        </div>
      </template>
    </a-table>
    <a-modal
      title="$t('operaterecords.b8')"
      centered
      :visible="visible"
      :closable="false"
      :ok-button-props="confirm_forbidden"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-textarea
        v-model="value"
        placeholder="$t('operaterecords.a15')"
        :auto-size="{ minRows: 10, maxRows: 10 }"
      />
    </a-modal>
  </div>
</template>

<script>
import { remarks } from "../../../api/api";

export default {
  name: "alarmtable",
  props: {
    data: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      visible: false,
      value: "",
      params: "",
      confirm_forbidden: { props: { disabled: true } },
      confirmLoading: false,
      pagination: {
        //分页需求配置
        total: 0, //数据总数
        pageSize: 5, //每页中显示10条数据
        showSizeChanger: true, //是否可以改变pagesize
        pageSizeOptions: ["5", "10", "50", "100"], //每页中显示的数据
        showTotal: (total) => `${total + $t("alarm.a16")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        // hideOnSinglePage:true,//只有一页时是否隐藏分页：默认值false
      },
      columns: [
        {
          title: "NO",
          dataIndex: "key",
          width: 65,
          ellipsis: true,
          scopedSlots: { customRender: "NO" },
          fixed: "left",
        },
        {
          title: this.$t('operaterecords.b1'),
          dataIndex: "DATE_TIME",
          width: 170,
          ellipsis: true,
        },
        {
          title: this.$t('operaterecords.b2'),
          dataIndex: "NOTIFICATION_MODE",
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('operaterecords.b3'),
          dataIndex: "FLC_ALARM_HISTORY_TYPE",
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t('operaterecords.b4'),
          dataIndex: "SITE_NAME",
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t('operaterecords.b5'),
          dataIndex: "EQUIPMENT_NAME1",
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t('operaterecords.b6'),
          dataIndex: "EQUIPMENT_NAME2",
          width: 190,
          ellipsis: true,
        },
        {
          title: this.$t('operaterecords.b7'),
          dataIndex: "SERVICE_TEXT",
          width: 130,
          ellipsis: true,
        },
        {
          title: this.$t('operaterecords.b8'),
          dataIndex: "DESCRIPTION",
          width: 140,
          ellipsis: true,
        },
        {
          title: this.$t('operaterecords.b9'),
          dataIndex: "chaozuo",
          scopedSlots: { customRender: "chaozuo" },
          width: 100,
          fixed: "right",
        },
      ],
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal !== "") {
          this.confirm_forbidden.props.disabled = false;
        } else {
          this.confirm_forbidden.props.disabled = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    remarks(key) {
      this.visible = true;
      this.params = key.PARAMS;
      console.log(key);
    },
    handleOk(e) {
      this.confirmLoading = true;
      let data = new FormData();
      data.append("params", this.params);
      data.append("comment", this.value);
      remarks(data)
        .then((res) => {
          this.confirmLoading = false;
          this.visible = false;
          this.$parent.onSubmit();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCancel(e) {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
button {
  margin-right: 10px;
}
</style>